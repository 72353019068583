import request from '@/utils/request'

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

export function serversList(query) {
    return request({
        url: '/client/servers',
        method: "get",
        params: query,
    })
}

export function serversDetail(id) {
    return request({
        url: '/client/servers/'+id,
        method: "get",
    })
}

export function newsList(query) {
    return request({
        url: '/client/news',
        method: "get",
        params: query,
    })
}
export function newsDetail(id) {
    return request({
        url: '/client/news/'+id,
        method: "get",
    })
}

export function commit(query) {
    return request({
        url: '/client/msg/commit',
        method: "post",
        data: query,
    })
}