<template>
  <div>
    <!-- ---------- -->

    <div class="footer_warp">
      <div class="footer_cont" style="display: flex;
      justify-content: center;align-items: center;">
        <!-- 上部 -->
        <div class="footer_cont_l">
          <!--上部 左 -->
          <div class="footer_cont_l_div" style="margin-left: -150px;">
            <div class="footer_cont_l_div_item">
              <h1>产品介绍</h1>
              <ul class="footer_cont_l_ul">
                <li v-for="(item, index) in arrList" :key="index" @click="toGuid(item.url, item.id)">
                  {{ item.title }}
                </li>
              </ul>
            </div>
            <div class="footer_cont_l_div_item">
              <h1>新闻资讯</h1>
              <ul class="footer_cont_l_ul">
                <li @click="toNews('/news')">
                  新闻资讯
                </li>
              </ul>
            </div>
            <div class="footer_cont_l_div_item2">
              <h1>联系我们</h1>
              <ul class="footer_cont_l_ul">
                <router-link to="lianxi">
                  <li>联系我们</li>
                </router-link>
              </ul>
            </div>
          </div>
        </div>
        <!-- top 中 -->
        <div class="footer_cont_c">
          <div>
            <p class="address">
              公司总部地址：杭州市西湖区金篷街368号1栋5层<br />
              邮箱：18072868582@163.com
            </p>
          </div>
        </div>
        <!-- top 右 -->
        <div class="footer_cont_r">
          <div>
            <div class="footer_cont_r_border">
              <!-- ../assets/img/gongzhonghao.jpg -->
              <img v-lazy="require('../assets/img/gongzhonghao.jpg')" alt="" />
            </div>
            <p>关注微信公众号</p>
          </div>
        </div>
      </div>
      <!-- 下部 -->
      <div class="footer_warp_btm" style="display: flex;
      justify-content: center;align-items: center;">
        <p style="display: flex;
      justify-content: center;align-items: center;">
          Copyright
          ©&ensp;&ensp;杭州小潮出行科技有限公司&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;#浙ICP备2022033917号-1
        </p>
      </div>
    </div>

    <!-- --------- -->
  </div>
</template>

<script>
export default {
  name: "Footer",
  data () {
    return {
      arrList: [
        {
          title: "滑板车 C5",
          url: "/C5",
        },
        {
          title: "滑板车 C7",
          url: "/C7",
        },
        {
          title: "两轮电动车",
          url: "/Bike",
        },
        {
          title: "智能中控",
          url: "/Iot",
        },
      ],
    };
  },

  mounted () {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
  },

  methods: {
    // 锚点跳转
    toGuid (path, id) {
      localStorage.setItem("toId", id);
      this.$router.push(path);
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
        localStorage.setItem("toId", "");
      }
    },
    toNews (url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.footer_warp {
  width: 100%;
  /* width: 1920px; */
  /* height: 388px; */
  background: #0d0c0c;
}
.footer_cont {
  width: 100%;
  margin: 0 auto;
  display: flex;
}
.footer_cont_l {
  font-size: 16px;
  font-weight: 500;
  color: #dddddd;
  line-height: 22px;
  padding-top: 77px;
  margin-right: 40px;
}
.footer_cont_l_title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #dddddd;
  line-height: 22px;
  width: 64px;
}
.footer_cont_l_ul {
  margin-top: 36px;
}
.footer_cont_l_ul li {
  text-align: center;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #fff;
  margin-top: 24px;
  cursor: pointer;
}
.footer_cont_l_div {
  display: flex;
}
.footer_cont_l_div_item {
  margin-right: 72px;
}
.footer_cont_c {
  margin-top: 70px;
  margin-left: 80px;
  margin-right: 80px;
}
.address {
  width: 467px;
  font-size: 20px;
  font-family: 'PingFangSC-Regular', 'PingFang SC';
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  margin-top: 42px;
}
.footer_cont_r {
  margin-top: 79px;
  /* margin-left: 70px; */
}
.footer_cont_r p {
  text-align: center;
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 17px;
}
.footer_cont_r_border {
  width: 111px;
  height: 109px;
  border: 1px solid #979797;
}
.footer_cont_r_border img {
  width: 95px;
  height: 95px;
  margin: 7px 8px;
}
.footer_warp_btm {
  width: 1600px;
  margin: 0 auto;
  height: 65px;
  margin-top: 56px;

  border-top: 1px solid #444444;
}
.footer_warp_btm p {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  line-height: 48px;
}
</style>


