<template>
  <div>
    <div class="wrap">
      <div class="logo">
        <img v-lazy="logoimg" alt="" @click="goBack">
      </div>
                                                                        ··
      <div class="nav">
        <el-button class="el-dropdown-link" style="width:90px" @click="goBack">
          首页
        </el-button>
        <el-dropdown @command="handleCommand">
          <el-button class="el-dropdown-link">
            产品介绍<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">滑板车-C5</el-dropdown-item>
            <el-dropdown-item command="b">滑板车-C7</el-dropdown-item>
            <el-dropdown-item command="c">两轮电动车</el-dropdown-item>
            <el-dropdown-item command="d">智能中控</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button-group>
          <el-button @click="funyuming('/product')">服务案例</el-button>
          <el-button @click="funyuming('/news')">新闻资讯</el-button>
          <el-button @click="funyuming('/About')">关于小潮</el-button>
          <el-button @click="funyuming('/lianxi')">联系我们</el-button>
        </el-button-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data () {
    return {
      logoimg: require("../assets/img/bgim1.jpg"),
    };
  },
  methods: {
    goBack () {
      this.$router.push("/");
    },
    funyuming (msg) {
      this.$router.push(msg);
    },
    handleCommand (command) {
      switch (command) {
        case 'a':
          this.$router.push("/C5");
          break;
        case 'b':
          this.$router.push("/C7");
          break;
        case 'c':
          this.$router.push("/Bike");
          break;
        case 'd':
          this.$router.push("/Iot");
          break;
      }
    },
    funRouter () {
      this.$router.push("/C5");
    },
  },
};
</script>
<style scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 101px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
}

.wrap > .logo > img {
  position: absolute;
  left: 360px;
  top: 23px;
  width: 181px;
  height: 54px;
  cursor: pointer;
}

.nav {
  width: 600px;
  position: absolute;
  left: 1000px;
  top: 30px;
}
</style>
